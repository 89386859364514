body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
	border: 2px solid #e7e7e7;
	border-radius: 4px;
	padding: .5rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 20px;
    border: 1px solid #ccc;
  	box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
}
.face {
	font-weight: 900;
}
.content {
	background-color: ;	
}
.title {
	background: ;
}
.h4 {
	font-weight: 700;
    padding: 1em 0em 0em;
}
.doubleline {
	margin-bottom: 1em;
	margin-top: 1em;
}
.col-lg-4 {
	margin-bottom: 1em;
	margin-top: 1em;
}
.button {
	color: #000;
	text-transform: capitalize;
	text-decoration: none;
	background: #b7cae0;
	padding: 20px;
	border-radius: 5px;
	display: inline-block;
	border: none;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.button:hover {
	color: #fff;
	letter-spacing: 1px;
	box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}
.header-color {
	  background: #000046;  /* fallback for old browsers */
	  background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
	  background: -webkit-gradient(linear, left top, right top, from(#1CB5E0), to(#000046));
	  background: -webkit-linear-gradient(left, #1CB5E0, #000046);
	  background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.landing-grid {
  background: #000046;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#1CB5E0), to(#000046));
  background: -webkit-linear-gradient(left, #1CB5E0, #000046);
  background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left:0;
  height: 100%;
  width: 100%;
}
.avatar-img {
  height: 250px;
  padding-top: 5em;
}
.banner-text {
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}
.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}
.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}
.banner-text p {
  color: white;
  font-size: 20px;
  padding:1em;
}
.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}
.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}
.projects-grid {
  display: flex;
}
.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100;
  justify-content: center;
  padding-top: 2em;
}
.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin: 20px;
}
.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}
.contact-grid p {
  font-family: 'Oxigen', sans-serif;
  font-weight: bold;
}
.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}
.contact-list {
  font-size: 67px;
  padding-right: 5rem;
}
.resume-right-col {
  background: #27221F;
  color: white;
  padding: 2em;
}
.customLinks {
  color: #000;
  text-decoration: none;
}
/* Services */
.frontImage {
background-image: url(/static/media/frontend-400x200.4a546c98.jpg);
}
.backendImage {
background-image: url(/static/media/backend-400x200.a2b7792b.jpg);
}
.ecommerceImage {
background-image: url(/static/media/ecommerce-cart-400x200.cb4f5aac.jpg);
}

.container-div{
text-align: center;
display: flex;
height: 100vh;
width: 100vh;
align-items: center;
justify-content: center;
}

/* MEDIA QUERIES */
@media screen and (min-width: 47.5em ) {
  
  
}

@media screen and ( min-width: 62.5em ) {



}




/* your style  */
#main {
 display: table;
 width: 100%;
 border:1px solid #777; 
 height: 300px;  
}

#bodytext, #editor {
  display: table-cell;
  padding: 5px;
  border:1px solid #777;
}

#bodytext {
  background: none repeat scroll 0 0;
  width: 50%;
  border:1px solid #777;
}
#editor {
  background: none repeat scroll 0 0;
  border:1px solid #777;
}



p.shine
{
    font-size: 1em;
    margin-top: 0.5em;
    width: 300px;
    text-decoration: none;
}
.shine
{
    background: #f2f5f2 -webkit-gradient(linear, left top, right top, from(#f2f5f2), to(#f2f5f2), color-stop(0.5, #000)) 0 0 no-repeat;
    -webkit-background-size: 70px;
    color: rgba(255, 255, 255, 0.4);
    -webkit-background-clip: text;
    -webkit-animation-name: shine;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    font-weight: 500;
    font-size: 1.7em;
    font-family: nunito sans,Arial,sans-serif;
    text-decoration: none !important;
}
@-webkit-keyframes shine
{
    0%
    {
        background-position: top left;
    }
    100%
    {
        background-position: top right;
    }
}

.nav-martin {
    margin: 0 auto;
    padding:0;
    min-width:30em;
    width:27em;
    height:28px;
    overflow:hidden;
    text-align:justify;
}
.nav-martin li {
    display:inline;
    list-style:none;
}
.nav-martin li.last {
    margin-right:100%;
}
.nav-martin li a {
    display:inline-block;
    padding:0px 15px 0;
    height:31px;
    color:#fff;
    vertical-align:middle;
    text-decoration:none;
    text-transform:capitalize;
}
.nav-martin li a:hover {
    color: #007bff;  
}
.searchColor {
  color: white;
  text-transform: capitalize;
}
.page-footer {
    bottom: 0;
    color: #fff;
}
.txt-margin {
  margin-left: 1.3em;
  margin-right: 0.4em;
}
.txt-margin-title {
  margin-left: 0.8em;
  font-family: "Roboto",sans-serif;
  font-size: 1.5rem;
}
.txt-copyright {
 text-align: center;
 margin-bottom: 2em;
}
.searchInputColor {
  color: white;
  text-transform: capitalize;
  border-radius: 5px;
  height: 35px;
  width: 9em;
}
.searchBottonColor {
  color: #000;
  text-transform: capitalize;
  border: 1px solid #007bff;
  height: 37px;
  width: 110px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  margin-left: 0.5em;
}

.videoheight {
  height: 900% !important;
}
