p.shine
{
    font-size: 1em;
    margin-top: 0.5em;
    width: 300px;
    text-decoration: none;
}
.shine
{
    background: #f2f5f2 -webkit-gradient(linear, left top, right top, from(#f2f5f2), to(#f2f5f2), color-stop(0.5, #000)) 0 0 no-repeat;
    -webkit-background-size: 70px;
    color: rgba(255, 255, 255, 0.4);
    -webkit-background-clip: text;
    -webkit-animation-name: shine;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    font-weight: 500;
    font-size: 1.7em;
    font-family: nunito sans,Arial,sans-serif;
    text-decoration: none !important;
}
@-webkit-keyframes shine
{
    0%
    {
        background-position: top left;
    }
    100%
    {
        background-position: top right;
    }
}

.nav-martin {
    margin: 0 auto;
    padding:0;
    min-width:30em;
    width:27em;
    height:28px;
    overflow:hidden;
    text-align:justify;
}
.nav-martin li {
    display:inline;
    list-style:none;
}
.nav-martin li.last {
    margin-right:100%;
}
.nav-martin li a {
    display:inline-block;
    padding:0px 15px 0;
    height:31px;
    color:#fff;
    vertical-align:middle;
    text-decoration:none;
    text-transform:capitalize;
}
.nav-martin li a:hover {
    color: #007bff;  
}
.searchColor {
  color: white;
  text-transform: capitalize;
}
.page-footer {
    bottom: 0;
    color: #fff;
}
.txt-margin {
  margin-left: 1.3em;
  margin-right: 0.4em;
}
.txt-margin-title {
  margin-left: 0.8em;
  font-family: "Roboto",sans-serif;
  font-size: 1.5rem;
}
.txt-copyright {
 text-align: center;
 margin-bottom: 2em;
}
.searchInputColor {
  color: white;
  text-transform: capitalize;
  border-radius: 5px;
  height: 35px;
  width: 9em;
}
.searchBottonColor {
  color: #000;
  text-transform: capitalize;
  border: 1px solid #007bff;
  height: 37px;
  width: 110px;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  margin-left: 0.5em;
}

.videoheight {
  height: 900% !important;
}