.card {
	border: 2px solid #e7e7e7;
	border-radius: 4px;
	padding: .5rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 20px;
    border: 1px solid #ccc;
  	box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
}
.face {
	font-weight: 900;
}
.content {
	background-color: ;	
}
.title {
	background: ;
}
.h4 {
	font-weight: 700;
    padding: 1em 0em 0em;
}
.doubleline {
	margin-bottom: 1em;
	margin-top: 1em;
}
.col-lg-4 {
	margin-bottom: 1em;
	margin-top: 1em;
}
.button {
	color: #000;
	text-transform: capitalize;
	text-decoration: none;
	background: #b7cae0;
	padding: 20px;
	border-radius: 5px;
	display: inline-block;
	border: none;
	transition: all 0.4s ease 0s;
}
.button:hover {
	color: #fff;
	letter-spacing: 1px;
	-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
	transition: all 0.4s ease 0s;
}
.header-color {
	  background: #000046;  /* fallback for old browsers */
	  background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
	  background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.landing-grid {
  background: #000046;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1CB5E0, #000046);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  position: fixed;
  top: 0;
  left:0;
  height: 100%;
  width: 100%;
}
.avatar-img {
  height: 250px;
  padding-top: 5em;
}
.banner-text {
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}
.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}
.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}
.banner-text p {
  color: white;
  font-size: 20px;
  padding:1em;
}
.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}
.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}
.projects-grid {
  display: flex;
}
.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100;
  justify-content: center;
  padding-top: 2em;
}
.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin: 20px;
}
.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}
.contact-grid p {
  font-family: 'Oxigen', sans-serif;
  font-weight: bold;
}
.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}
.contact-list {
  font-size: 67px;
  padding-right: 5rem;
}
.resume-right-col {
  background: #27221F;
  color: white;
  padding: 2em;
}
.customLinks {
  color: #000;
  text-decoration: none;
}
/* Services */
.frontImage {
background-image: url("./../assets/frontend-400x200.jpg");
}
.backendImage {
background-image: url("./../assets/backend-400x200.jpg");
}
.ecommerceImage {
background-image: url("./../assets/ecommerce-cart-400x200.jpg");
}

.container-div{
text-align: center;
display: flex;
height: 100vh;
width: 100vh;
align-items: center;
justify-content: center;
}

/* MEDIA QUERIES */
@media screen and (min-width: 47.5em ) {
  
  
}

@media screen and ( min-width: 62.5em ) {



}



