/* your style  */
#main {
 display: table;
 width: 100%;
 border:1px solid #777; 
 height: 300px;  
}

#bodytext, #editor {
  display: table-cell;
  padding: 5px;
  border:1px solid #777;
}

#bodytext {
  background: none repeat scroll 0 0;
  width: 50%;
  border:1px solid #777;
}
#editor {
  background: none repeat scroll 0 0;
  border:1px solid #777;
}


